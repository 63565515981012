import { Button } from "../components"
import { Link } from "react-router-dom"
import { useAppContext } from "../context/AppContext"
import { useEffect } from "react"
import { useTranslation } from "react-i18next"

export const Confirm = () => {
  const [state] = useAppContext()
  const jsonString = JSON.stringify(state)
  const { t } = useTranslation()

  useEffect(() => {
    const fileInput = document.querySelector("#json-file")

    const jsonFile = new File(
      [jsonString],
      `${state.firstName}-${state.lastName}.json`,
      {
        type: "text/json",
        lastModified: new Date(),
      }
    )

    const dataTransfer = new DataTransfer()
    dataTransfer.items.add(jsonFile)
    fileInput.files = dataTransfer.files
  }, [jsonString, state.firstName, state.lastName])

  return (
    <div>
      <h2>{t("sonuc.title")}</h2>
      <p className='mb-2 mt-4'>{t("sonuc.explanation")}</p>
      <p className='fs-4 fst-italic mb-4'>Ezgin Yetiş, Şafak Turgut</p>
      <form
        name='result'
        method='post'
        enctype='multipart/form-data'
        value='Yeni veri alındı. %{formName} (%{submissionId})'
      >
        <input type='hidden' name='form-name' value='result' />
        <textarea name='data' value={jsonString} hidden readOnly></textarea>
        <input type='file' name='data-file' id='json-file' hidden readOnly />
        <Link className={`btn btn-secondary me-2`} to='/sorular/18'>
          &larr; {t("buttons.previous")}
        </Link>
        <Button>{t("buttons.send")} &uarr;</Button>
      </form>
    </div>
  )
}
