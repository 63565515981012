import React from "react"

export const RadioField = ({ children, label, error, className }) => {
  const id = getChildId(children)

  return (
    <div className={`form-check radio-wrapper ${className}`}>
      <label htmlFor={id} className='form-check-label'>
        {label}
      </label>
      {children}
    </div>
  )
}

// Get id prop from a child element
export const getChildId = (children) => {
  const child = React.Children.only(children)

  if ("id" in child?.props) {
    return child.props.id
  }
}
