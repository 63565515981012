import { useForm } from "react-hook-form"
import { useNavigate, Link } from "react-router-dom"
import { useAppContext } from "../context/AppContext"
import { useTranslation } from "react-i18next"

import {
  Button,
  Form,
  Input,
  Image,
  ImageField,
  RadioField,
  ButtonField,
} from "../components"

// Images
import lakuna from "../assets/Images/multiple-choice/q9/lakuna.jpg"
import notrDesen from "../assets/Images/multiple-choice/q9/notr-desen.jpg"
import tonaltiDesen from "../assets/Images/multiple-choice/q9/tonalti-desen.jpg"

export const MCQuestion9 = () => {
  const [state, setState] = useAppContext()
  const { t } = useTranslation()

  const {
    handleSubmit,
    register,
    watch,
    formState: { errors },
  } = useForm({
    defaultValues: state,
    mode: "onSubmit",
    shouldUnregister: true,
  })

  const navigate = useNavigate()
  const question = watch("q9")

  const saveData = (data) => {
    setState((prev) => ({ ...prev, ...data }))
    navigate("/sorular/10")
  }

  return (
    <Form onSubmit={handleSubmit(saveData)}>
      <fieldset className='container-fluid'>
        <div className='row justify-content-center gy-4'>
          <ImageField className='col-6 col-xl-4 text-center'>
            <Image
              smImageSrc={lakuna}
              lgImageSrc={lakuna}
              alt='tokat madimaklar evi'
              ratio={30}
            />
            <small className='fst-italic'>{t("imageSources.madimaklar")}</small>
          </ImageField>
          <p className='col-4 col-xl-3'>{t("imageZoomExplanation")}</p>
        </div>
        <div className='row px-2 mt-4'>
          <p>
            <span className='h4'>9) </span> {t("q9")}
          </p>
        </div>
        <div className='row justify-content-center gy-4 mt-1'>
          <ImageField>
            <RadioField label='A' error={errors?.q9}>
              <Input
                {...register("q9", {
                  required: "Seçeneklerden birinin seçilmesi zorunludur.",
                })}
                id='q9-1'
                type='radio'
                className='form-check-input'
                value='notrDesen'
              />
            </RadioField>
            <div className='col'>
              <Image
                smImageSrc={notrDesen}
                lgImageSrc={notrDesen}
                alt='tokat madimaklar evi'
                ratio={50}
              />
            </div>
          </ImageField>
          <ImageField>
            <RadioField label='B' error={errors?.q9}>
              <Input
                {...register("q9", {
                  required: "Seçeneklerden birinin seçilmesi zorunludur.",
                })}
                id='q9-2'
                type='radio'
                className='form-check-input'
                value='tonaltiDesen'
              />
            </RadioField>
            <div className='col'>
              <Image
                smImageSrc={tonaltiDesen}
                lgImageSrc={tonaltiDesen}
                alt='tokat madimaklar evi'
                ratio={50}
              />
            </div>
          </ImageField>
        </div>
        <div className='row flex-column align-items-center mt-5 p-3'>
          <RadioField
            label={t("other")}
            error={errors?.q9}
            className='col-lg-10 col-xl-12'
          >
            <Input
              {...register("q9", {
                required: "Seçeneklerden birinin seçilmesi zorunludur.",
              })}
              id='q9-3'
              type='radio'
              className='form-check-input'
              value='other'
            />
          </RadioField>
          {question === "other" && (
            <textarea
              name='q9-other'
              rows='4'
              className='form-control mt-3'
              {...register("q9-other")}
            ></textarea>
          )}
          <ButtonField>
            <Link className={`btn btn-secondary me-2`} to='/sorular/8'>
              &larr; {t("buttons.previous")}
            </Link>
            <Button>{t("buttons.next")} &rarr;</Button>
            {errors.q9 && <p className='ps-3 m-0 text-danger'>{t("error")}</p>}
          </ButtonField>
        </div>
      </fieldset>
    </Form>
  )
}
