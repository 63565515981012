import React from "react"
import { Link } from "react-router-dom"
import { useTranslation } from "react-i18next"

export const PageNotFound = () => {
  const { t } = useTranslation()

  return (
    <div className='text-center' style={{ paddingTop: "25vh" }}>
      <h1 className='mb-4'>404</h1>
      <p className='h3 mb-4 pb-3'>{t("pageNotFound")}</p>
      <Link className={`btn btn-primary`} to='/'>
        &larr; {t("buttons.homepage")}
      </Link>
    </div>
  )
}
