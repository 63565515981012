import { useTranslation } from "react-i18next"

export const Navbar = () => {
  const { i18n } = useTranslation()
  return (
    <nav className='nav mb-3 mt-3 pt-3 pb-3 justify-content-end gap-3'>
      <button
        className={
          i18n.resolvedLanguage === "en"
            ? "btn border border-black fw-bold"
            : "btn"
        }
        onClick={() => {
          i18n.changeLanguage("en")
        }}
      >
        EN
      </button>
      <div className='vertical-line'></div>
      <button
        className={
          i18n.resolvedLanguage === "tr"
            ? "btn border border-black fw-bold"
            : "btn"
        }
        onClick={() => {
          i18n.changeLanguage("tr")
        }}
      >
        TR
      </button>
    </nav>
  )
}
