import "./App.css"

import { Suspense } from "react"

import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom"
import { AppProvider } from "./context/AppContext"

import { PersonalInfo } from "./pages/PersonalInfo"
import { MCQuestion1 } from "./pages/MCQuestion1"
import { MCQuestion2 } from "./pages/MCQuestion2"
import { MCQuestion3 } from "./pages/MCQuestion3"
import { MCQuestion4 } from "./pages/MCQuestion4"
import { MCQuestion5 } from "./pages/MCQuestion5"
import { MCQuestion6 } from "./pages/MCQuestion6"
import { MCQuestion7 } from "./pages/MCQuestion7"
import { MCQuestion8 } from "./pages/MCQuestion8"
import { MCQuestion9 } from "./pages/MCQuestion9"
import { MCQuestion10 } from "./pages/MCQuestion10"
import { OCQuestion1 } from "./pages/OCQuestion1"
import { OCQuestion2 } from "./pages/OCQuestion2"
import { OCQuestion3 } from "./pages/OCQuestion3"
import { OCQuestion4 } from "./pages/OCQuestion4"
import { OCQuestion5 } from "./pages/OCQuestion5"
import { SatQuestion1 } from "./pages/SatQuestion1"
import { SatQuestion2 } from "./pages/SatQuestion2"
import { SatQuestion3 } from "./pages/SatQuestion3"
import { Confirm } from "./pages/Confirm"
import { PageNotFound } from "./pages/PageNotFound"
import { Navbar } from "./components/Navbar"

const App = () => {
  return (
    <div className='container'>
      <AppProvider>
        <Router>
          <Navbar />
          <Routes>
            <Route path='/' element={<PersonalInfo />} />{" "}
            <Route path='/sorular' element={<Navigate to='/sorular/1' />} />
            <Route path='/sorular/1' element={<MCQuestion1 />} />
            <Route path='/sorular/2' element={<MCQuestion2 />} />
            <Route path='/sorular/3' element={<MCQuestion3 />} />
            <Route path='/sorular/4' element={<MCQuestion4 />} />
            <Route path='/sorular/5' element={<MCQuestion5 />} />
            <Route path='/sorular/6' element={<MCQuestion6 />} />
            <Route path='/sorular/7' element={<MCQuestion7 />} />
            <Route path='/sorular/8' element={<MCQuestion8 />} />
            <Route path='/sorular/9' element={<MCQuestion9 />} />
            <Route path='/sorular/10' element={<MCQuestion10 />} />
            <Route path='/sorular/11' element={<OCQuestion1 />} />
            <Route path='/sorular/12' element={<OCQuestion2 />} />
            <Route path='/sorular/13' element={<OCQuestion3 />} />
            <Route path='/sorular/14' element={<OCQuestion4 />} />
            <Route path='/sorular/15' element={<OCQuestion5 />} />
            <Route path='/sorular/16' element={<SatQuestion1 />} />
            <Route path='/sorular/17' element={<SatQuestion2 />} />
            <Route path='/sorular/18' element={<SatQuestion3 />} />
            <Route path='/sonuc' element={<Confirm />} />
            <Route path='*' element={<PageNotFound />} />
          </Routes>
        </Router>
      </AppProvider>
    </div>
  )
}

export default function WrappedApp() {
  return (
    <Suspense fallback='...loading'>
      <App />
    </Suspense>
  )
}
