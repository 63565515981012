import React from "react"

export const TextField = ({ children, label, error }) => {
  const id = getChildId(children)

  return (
    <div className='col-12 col-md-8 col-lg-6 mb-3'>
      <label htmlFor={id} className='form-label'>
        {label}
      </label>
      {children}
      {error && <small className='text-danger'>{error.message}</small>}
    </div>
  )
}

// Get id prop from a child element
export const getChildId = (children) => {
  const child = React.Children.only(children)

  if ("id" in child?.props) {
    return child.props.id
  }
}
