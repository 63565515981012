import ReactImageMagnify from "react-image-magnify"
import { useImageSize } from "react-image-size"

export const Image = ({
  smImageSrc,
  lgImageSrc,
  alt,
  left = "20%",
  top = "20%",
  ratio,
  zoomRatio = [1, 1],
}) => {
  const [ImageDimensions, { ImageLoading, ImageError }] =
    useImageSize(lgImageSrc)

  if (ImageLoading) {
    return <div>Loading...</div>
  }

  if (ImageError) {
    return <div>Error image not found.</div>
  }

  return (
    <ReactImageMagnify
      {...{
        smallImage: {
          src: smImageSrc,
          alt: alt,
          isFluidWidth: true,
        },
        largeImage: {
          src: lgImageSrc,
          width: (ImageDimensions?.width * ratio) / 100,
          height: (ImageDimensions?.height * ratio) / 100,
        },
        enlargedImageContainerStyle: {
          zIndex: 1,
          left: left,
          top: top,
        },
        enlargedImageContainerDimensions: {
          width: "100%",
          height: `${100 * zoomRatio[1]}%`,
        },
      }}
    />
  )
}
