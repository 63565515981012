import React from "react"

export const RangeField = ({ children, label, error }) => {
  const id = getChildId(children)

  return (
    <div className='fw-bold'>
      <label htmlFor={id} className='form-label'>
        {label}
      </label>
      {children}
      {error && (
        <p className='m-0'>
          <small className='text-danger'>{error.message}</small>
        </p>
      )}
    </div>
  )
}

// Get id prop from a child element
export const getChildId = (children) => {
  const child = React.Children.only(children)

  if ("id" in child?.props) {
    return child.props.id
  }
}
