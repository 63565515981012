import { useForm } from "react-hook-form"
import { useNavigate } from "react-router-dom"
import { useAppContext } from "../context/AppContext"
import { Button, TextField, Form, Input } from "../components"
import { useTranslation } from "react-i18next"

export const PersonalInfo = () => {
  const [state, setState] = useAppContext()
  const navigate = useNavigate()
  const { t } = useTranslation()

  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm({
    defaultValues: state,
    mode: "onSubmit",
  })

  const saveData = (data) => {
    setState((prev) => ({ ...prev, ...data }))
    console.log(state)
    navigate("/sorular/1")
  }

  return (
    <Form onSubmit={handleSubmit(saveData)}>
      <fieldset className='d-flex flex-column align-items-center'>
        <h3 className='text-center mb-5'>{t("homepage.title")}</h3>
        <p className='col-12 col-md-8 col-lg-6 mb-2'>
          {t("homepage.researchExplanation")}
        </p>
        <h4 className='col-12 col-md-8 col-lg-6 text-center mt-3'>
          {t("homepage.form.title")}
        </h4>
        <hr className='col-12 col-md-8 col-lg-6 mb-4' />
        <TextField
          label={t("homepage.form.firstName")}
          error={errors?.firstName}
        >
          <Input
            {...register("firstName", {
              required: t("homepage.error"),
            })}
            id='first-name'
            className='form-control'
          />
        </TextField>
        <TextField label={t("homepage.form.lastName")} error={errors?.lastName}>
          <Input
            {...register("lastName", {
              required: t("homepage.error"),
            })}
            id='last-name'
            className='form-control'
          />
        </TextField>
        <TextField label={t("homepage.form.age")} error={errors?.age}>
          <Input
            {...register("age", {
              required: t("homepage.error"),
            })}
            id='age'
            className='form-control'
          />
        </TextField>
        <TextField
          label={t("homepage.form.phoneNumber")}
          error={errors?.phoneNumber}
        >
          <Input
            {...register("phoneNumber", {
              required: t("homepage.error"),
            })}
            id='phone-number'
            className='form-control'
          />
        </TextField>
        <TextField label={t("homepage.form.email")} error={errors?.email}>
          <Input
            {...register("email", {
              required: t("homepage.error"),
            })}
            type='email'
            id='email'
            className='form-control'
          />
        </TextField>

        <TextField label={t("homepage.form.company")} error={errors?.company}>
          <Input
            {...register("company", {
              required: t("homepage.error"),
            })}
            id='company'
            className='form-control'
          />
        </TextField>
        <TextField
          label={t("homepage.form.companyRole")}
          error={errors?.companyRole}
        >
          <Input
            {...register("companyRole")}
            id='companyRole'
            className='form-control'
          />
        </TextField>
        <TextField
          label={t("homepage.form.associateDegree")}
          error={errors?.associateDegree}
        >
          <Input
            {...register("associateDegree")}
            id='associateDegree'
            className='form-control'
          />
        </TextField>
        <TextField
          label={t("homepage.form.bachelorsDegree")}
          error={errors?.bachelorsDegree}
        >
          <Input
            {...register("bachelorsDegree")}
            id='bachelorsDegree'
            className='form-control'
          />
        </TextField>
        <TextField
          label={t("homepage.form.mastersDegree")}
          error={errors?.mastersDegree}
        >
          <Input
            {...register("mastersDegree")}
            id='mastersDegree'
            className='form-control'
          />
        </TextField>
        <TextField label={t("homepage.form.phd")} error={errors?.phd}>
          <Input {...register("phd")} id='phd' className='form-control' />
        </TextField>
        <div className='col-12 col-md-8 col-lg-6 my-2'>
          <Button>{t("buttons.next")} &rarr;</Button>
        </div>
      </fieldset>
    </Form>
  )
}
