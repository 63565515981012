import { useState, useEffect } from "react"
import { useAppContext } from "../context/AppContext"
import { useForm } from "react-hook-form"
import { useNavigate, Link } from "react-router-dom"
import { useTranslation } from "react-i18next"

import {
  Button,
  Form,
  Input,
  Image,
  ImageField,
  RangeField,
  ButtonField,
} from "../components"

//Images
import lakuna from "../assets/Images/color-selection/q15/lakuna.jpg"
import transparent from "../assets/Images/color-selection/q15/transparent.png"

export const OCQuestion5 = () => {
  const [state, setState] = useAppContext()
  const [colors, setColors] = useState(state.q15)
  const { t } = useTranslation()

  const {
    handleSubmit,
    register,
    watch,
    formState: { errors },
  } = useForm({
    defaultValues: state,
    mode: "onSubmit",
    shouldUnregister: true,
  })

  useEffect(() => {
    const subscribe = watch((value) => {
      setColors({
        mainColor: value.mainColor,
        black: value.black,
        blue: value.blue,
      })
    })

    return () => {
      subscribe.unsubscribe()
    }
  }, [watch])

  const navigate = useNavigate()

  const saveData = (data) => {
    setState((prev) => ({ ...prev, q15: { ...data } }))
    navigate("/sorular/16")
  }

  return (
    <Form onSubmit={handleSubmit(saveData)}>
      <fieldset className='container-fluid'>
        <div className='row justify-content-center gy-4'>
          <ImageField className='col-6 col-xl-4 text-center'>
            <Image
              smImageSrc={lakuna}
              lgImageSrc={lakuna}
              alt='tokat madimaklar evi'
              ratio={50}
            />
            <small className='fst-italic'>{t("imageSources.madimaklar")}</small>
          </ImageField>
          <p className='col-4 col-xl-3'>{t("imageZoomExplanation")}</p>
        </div>
        <div className='row px-2 mt-4'>
          <p>
            <span className='h4'>15) </span> {t("q15")}
          </p>
        </div>
        <div className='row gy-4 mt-3 p-3'>
          <div className='col-12 col-md-8 position-relative p-0'>
            <div
              className='position-absolute h-100 w-100 start-0 top-0 z-index-3'
              style={{
                backgroundColor: `rgba(0,0,255,${Number(colors.blue) / 100})`,
              }}
            ></div>
            <div
              className='position-absolute h-100 w-100 start-0 top-0 z-index-2'
              style={{
                backgroundColor: `rgba(0,0,0,${Number(colors.black) / 100})`,
              }}
            ></div>
            <div
              className='position-absolute h-100 w-100 start-0 top-0 z-index-1'
              style={{
                backgroundColor: `rgba(255,138,0,${
                  Number(colors.mainColor) / 100
                })`,
              }}
            ></div>
            <img
              className='img-fluid'
              src={transparent}
              alt='tokat madimaklar evi'
            />
          </div>
          <div className='col-12 col-md-4 flex-column p-0 ps-md-4'>
            <RangeField
              label={t("rangeLabels.mainColor") + `: ${colors.mainColor}`}
              error={errors?.q15}
            >
              <Input
                {...register("mainColor")}
                id='q15-main'
                type='range'
                className='form-range'
                min='0'
                max='60'
                step='1'
                value={colors.mainColor}
              />
            </RangeField>
            <RangeField
              label={t("rangeLabels.black") + `: ${colors.black}`}
              error={errors?.q15}
            >
              <Input
                {...register("black")}
                id='q15-black'
                type='range'
                className='form-range'
                min='0'
                max='90'
                step='1'
                value={colors.black}
              />
            </RangeField>
            <RangeField
              label={t("rangeLabels.blue") + `: ${colors.blue}`}
              error={errors?.q15}
            >
              <Input
                {...register("blue")}
                id='q15-blue'
                type='range'
                className='form-range'
                min='0'
                max='30'
                step='1'
                value={colors.blue}
              />
            </RangeField>
          </div>
          <ButtonField className='mb-3 p-0'>
            <Link className={`btn btn-secondary me-2`} to='/sorular/14'>
              &larr; {t("buttons.previous")}
            </Link>
            <Button>{t("buttons.next")} &rarr;</Button>
          </ButtonField>
        </div>
      </fieldset>
    </Form>
  )
}
