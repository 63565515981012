import { useState, useEffect } from "react"
import { useAppContext } from "../context/AppContext"
import { useForm } from "react-hook-form"
import { useNavigate, Link } from "react-router-dom"
import { useTranslation } from "react-i18next"

import {
  Button,
  Form,
  Input,
  Image,
  ImageField,
  RangeField,
  ButtonField,
} from "../components"

import original from "../assets/Images/saturation-change/q16/original.jpg"
import lakuna from "../assets/Images/saturation-change/q16/lakuna.jpg"
import transparent from "../assets/Images/saturation-change/q16/transparent.png"

export const SatQuestion1 = () => {
  const [state, setState] = useAppContext()
  const [filters, setFilters] = useState(state.q16)
  const { t } = useTranslation()

  const {
    handleSubmit,
    register,
    watch,
    formState: { errors },
  } = useForm({
    defaultValues: state,
    mode: "onSubmit",
    shouldUnregister: true,
  })

  useEffect(() => {
    const subscribe = watch((value) => {
      setFilters({
        saturation: value.saturation,
        brightness: value.brightness,
      })
    })

    return () => {
      subscribe.unsubscribe()
    }
  }, [watch])

  const navigate = useNavigate()

  const saveData = (data) => {
    setState((prev) => ({ ...prev, q16: { ...data } }))
    navigate("/sorular/17")
  }

  return (
    <Form onSubmit={handleSubmit(saveData)}>
      <fieldset className='container-fluid'>
        <div className='row justify-content-center gy-4'>
          <ImageField className='col-6 col-xl-4 text-center'>
            <Image
              smImageSrc={lakuna}
              lgImageSrc={lakuna}
              alt='edirne suleyman pasa'
              ratio={25}
            />
            <small className='fst-italic'>
              {t("imageSources.suleymanPasa")}
            </small>
          </ImageField>
          <p className='col-4 col-xl-3'>{t("imageZoomExplanation")}</p>
        </div>
        <div className='row px-2 mt-4'>
          <p>
            <span className='h4'>16) </span> {t("q16")}
          </p>
        </div>
        <div className='row gy-4 mt-3 p-3'>
          <div className='col-12 col-md-8 position-relative p-0'>
            <img
              className='position-absolute h-100 w-100 start-0 top-0 z-index-1'
              src={original}
              alt='edirne suleyman pasa'
              style={{
                filter: `saturate(${filters.saturation}%) brightness(${filters.brightness}%)`,
              }}
            ></img>
            <img
              className='img-fluid'
              src={transparent}
              alt='edirne suleyman pasa'
            />
          </div>
          <div className='col-12 col-md-4 p-0 ps-md-4'>
            <RangeField
              label={
                t("rangeLabels.saturation") +
                `: ${Number(filters.saturation) - 100}`
              }
              error={errors?.q16}
            >
              <Input
                {...register("saturation")}
                id='q16-saturation'
                type='range'
                className='form-range'
                min='50'
                max='150'
                step='1'
                value={filters.saturation}
              />
            </RangeField>
            <RangeField
              label={
                t("rangeLabels.lightness") +
                `: ${Number(filters.brightness) - 100}`
              }
              error={errors?.q16}
            >
              <Input
                {...register("brightness")}
                id='q16-brightness'
                type='range'
                className='form-range'
                min='50'
                max='150'
                step='1'
                value={filters.brightness}
              />
            </RangeField>
          </div>
          <ButtonField className='mb-3 p-0'>
            <Link className={`btn btn-secondary me-2`} to='/sorular/15'>
              &larr; {t("buttons.previous")}
            </Link>
            <Button>{t("buttons.next")} &rarr;</Button>
          </ButtonField>
        </div>
      </fieldset>
    </Form>
  )
}
