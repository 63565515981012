import { useState, useContext, createContext } from "react"

const AppContext = createContext({})

export const AppProvider = ({ children }) => {
  const value = useState({
    firstName: "",
    lastName: "",
    age: "",
    phoneNumber: "",
    email: "",
    company: "",
    companyRole: "",
    associateDegree: "",
    bachelorsDegree: "",
    mastersDegree: "",
    phd: "",
    q1: "",
    q2: "",
    q3: "",
    q4: "",
    q5: "",
    q6: "",
    q7: "",
    q8: "",
    q9: "",
    q10: "",
    q11: {
      mainColor: "0",
      black: "0",
      blue: "0",
    },
    q12: {
      mainColor: "0",
      black: "0",
      blue: "0",
    },
    q13: {
      mainColor: "0",
      black: "0",
      blue: "0",
    },
    q14: {
      mainColor: "0",
      black: "0",
      blue: "0",
    },
    q15: {
      mainColor: "0",
      black: "0",
      blue: "0",
    },
    q16: {
      saturation: "50",
      brightness: "50",
    },
    q17: {
      saturation: "50",
      brightness: "50",
    },
    q18: {
      saturation: "50",
      brightness: "50",
    },
  })
  return <AppContext.Provider value={value}>{children}</AppContext.Provider>
}

export const useAppContext = () => {
  const context = useContext(AppContext)
  if (!context) {
    throw new Error("useAppContext must be used within the AppProvider")
  }
  return context
}
